.i{
    display: flex;
    height: 100vh;

}

.i-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-right{
    flex: 1;
    position: relative;
}
.i-left-wrapper{
    padding: 50px;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.i-intro{
    font-size: 30px;
    font-weight: 30;
}
.i-name{
    font-size: 60px;
    margin-top: 5px;
}
.i-title{
    height: 50px;
    overflow: hidden;
}
.i-title-wrapper{
    height: 100%;
}
.i-title-item{
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: green;
    display: flex;
    align-items: center;
}
.i-scroll {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
  }
.i-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    clip-path: polygon(100% 0%, 100% 63%, 100% 100%, 25% 100%, 5% 50%, 25% 0%);
}
.i-bg{
    clip-path: polygon(100% 0%, 100% 63%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: green;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}
.i-desc{
    font-weight:500
}

@media screen and (max-width: 480px) {
    .i {
      flex-direction: column;
    }
  
    .i-left-wrapper {
    
      padding: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
    }
  
    .i-desc{
      display: none;
    }
    
  }